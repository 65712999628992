import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";
import { formatDataValue } from "../../helpers/formatFuncs";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<keyof DisplayData, HeaderMappingItem> = {
  source: {
    name: "Quelle/Blog",
    type: "string",
    digits: 0,
    attach: "",
  },
  posts: {
    name: "Anzahl Artikel",
    type: "number",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  leadRate: {
    name: "Leads/Artikel",
    type: "number",
    digits: 4,
    attach: "",
  },
};

interface ApiData {
  [date: string]: {
    [name: string]: {
      posts: number;
      leads: number;
    };
  };
}

interface DisplayData {
  source: string;
  posts: number;
  leads: number;
  leadRate: number;
}

const getData: (filterState: IFilterState) => Promise<DisplayData[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  const data = (await fetchData<ApiData>(
    "/api/gurupress/article-distribution",
    options
  )) as ApiData;

  // process data, sum + calculate lead rate
  const dataAggregation: Record<
    string,
    {
      posts: number;
      leads: number;
    }
  > = {};
  for (const [_, values] of Object.entries(data)) {
    for (const [name, value] of Object.entries(values)) {
      if (!dataAggregation[name]) {
        dataAggregation[name] = {
          posts: 0,
          leads: 0,
        };
      }
      dataAggregation[name].posts += value.posts;
      dataAggregation[name].leads += value.leads;
    }
  }
  const displayData: DisplayData[] = [];
  for (const [source, value] of Object.entries(dataAggregation)) {
    displayData.push({
      source,
      posts: value.posts,
      leads: value.leads,
      leadRate: value.leads / value.posts,
    });
  }
  return displayData;
};
export const ArticleDistributionTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof DisplayData>("leadRate");
  const [data, setData] = useState<DisplayData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData().catch((reason) => {
      console.error("Error fetching data", reason);
    });
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleRequestSort = (property: keyof DisplayData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setData(
      data.sort(
        (a, b) => (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1)
      )
    );
  };

  const createSortHandler = (property: keyof DisplayData) => () => {
    handleRequestSort(property);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() => exportToExcel(data, "Artikel-Verteilung-Börse-Global")}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                <TableCell key={key}>
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : "asc"}
                    onClick={createSortHandler(key as keyof DisplayData)}
                  >
                    {value.name || key}
                    {orderBy === key ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<DisplayData>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {formatDataValue(
                      row[key as keyof DisplayData],
                      value.type,
                      value.digits,
                      value.attach
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ArticleDistributionTable;
