//./react-app/src/helpers/sortingsFuncs.ts

export type Order = "asc" | "desc";

export function descendingComparator<T, Key extends keyof T>(
  a: T,
  b: T,
  orderBy: Key
): number {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  // Handle null or undefined values
  if (aValue == null && bValue == null) return 0;
  if (aValue == null) return 1; // Nulls last
  if (bValue == null) return -1;

  // Check if the values are numbers (including decimals for percentages)
  if (typeof aValue === "number" && typeof bValue === "number") {
    return bValue - aValue; // Direct numeric comparison
  }

  // Fallback to string comparison if values are not numbers
  const aString = String(aValue);
  const bString = String(bValue);
  return bString.localeCompare(aString);
}

export function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function sortedRowInformation<Data>(
  rowArray: Data[],
  comparator: (a: Data, b: Data) => number
) {
  const stabilizedRowArray = rowArray.map(
    (el, index) => [el, index] as [Data, number]
  );
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // Stabilize sort by original index
  });
  return stabilizedRowArray.map((el) => el[0]);
}
