// ./react-app/src/components/FilterBar.tsx
import React, { ChangeEvent, FC, useContext, useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { FilterContext } from "../contexts/FilterContext";
import { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Customer = {
  id: string;
  name: string;
  chart_color: string;
};

type Newsletter = {
  id: string;
  name: string;
};

const displayOptions: Record<string, string> = {
  daily: "Täglich",
  weekly: "Wöchentlich",
  monthly: "Monatlich",
  yearly: "Jährlich",
};

const articlesGroupingOptions: Record<string, string> = {
  author: "Autor",
  asset: "Aktie/Asset",
  word_count: "Wortanzahl",
};

const articleTypeOptions: Record<string, string> = {
  all: "Alle",
  internal: "Intern (Redaktion + NL)",
  redaktion: "Redaktion",
  newsletter: "Newsletter",
  external: "Extern (Guru)",
  updated: "Update Artikel (Opitz)",
  viral_post: "Viraler Zusatzartikel",
  idea_all: "Ideen (Alle)",
  aktiencheck_idea: "Lead-Ideen",
  search_volume_idea: "Such-Volumen-Ideen",
  top_flop_idea: "Top-Flop-Ideen"
};

const samGroupingOptions: Record<string, string> = {
  send_type: "Main/Dup Versand",
  customer: "SAM Kunde",
  recipients: "Empfänger (Standard / Non Opener)",
};

const lpsLeadsGroupByOptions: Record<string, string> = {
  lead_status: "Lead Status",
  source: "Lead Quelle",
};

const leadSourceOptions: Record<string, string> = {
  Finanztrends: "Finanztrends",
  Aktiencheck: "Aktiencheck",
  Push: "Push-Nachrichten",
  KapitalmarktExperten: "Kapitalmarkt-Experten",
  NTG24: "NTG24",
};

const hostNameOptions: Record<string, string> = {
  "finanztrends.de": "Finanztrends",
  "boerse-global.de": "Boerse-Global",
  "gurupress.de": "Gurupress",
  "boersia.de": "Boersia",
  "boersify.de": "Boersify",
  "neopresse.com": "Neopresse",
};

const viewChannelOptions: Record<string, string> = {
  all: "Alle",
  organic: "Organisch",
  direct: "Direkt",
  referral: "Referral",
  unassigned: "Unbekannt",
};

const viewReferrerOptions: Record<string, string> = {
  all: "Alle",
  google_discover: "Google Discover",
  google_search: "Google Search",
  google_news: "Google News",
  google_combined: "Google (alle)",
  finanztrends: "Finanztrends",
  bing: "Bing",
  other: "Sonstige",
  none: "Direkt/Unbekannt",
};

/*const soldLeadSourceOptions: Record<string, string> = {
  all: "Alle",
  sam: "SAM",
  nl: "Newsletter",
  welcome_campaign: "ENL Welcome Campaign",
  push: "Push",
  other: "Sonstige",
};*/

export const leadStatusOptions: Record<string, string> = {
  all: "Alle",
  new: "Neu",
  //unconfirmed: "Unbestätigt", -> not possible as doi_date has always to be given in request
  duplicate: "Dubletten (insg)",
  duplicate_but_passed: "Dubletten (weitergegeben)",
  duplicate_no_export: "Dubletten (nicht exportiert)",
  updated: "Tags geupdated",
  error: "Fehler",
  undefined: "Unbekannt",
  not_exported: "Nicht exportiert",
};

export const activeOptions: Record<number, string> = {
  1: "Aktiv",
  0: "Deaktiviert",
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 250,
    },
  },
};

const defaultFilterConfig: Array<string> = ["startDate", "endDate"];

const FilterBar: FC<{ filterConfig?: Array<string> }> = ({
  filterConfig = defaultFilterConfig,
}) => {
  const { filterState, setFilterState } = useContext(FilterContext)!;
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
   useEffect(() => {
         fetch("/api/available-customers")
           .then((response) => response.json())
           .then((data) => {
             if (!data) {
               console.error("Error on fetching available customers");
               return;
             }
             setCustomers(data);
             setFilterState((prevState) => ({ ...prevState, customerData: data }));
           });
       }, []);
     
       useEffect(() => {
         fetch("/api/mailchimp/available-newsletters")
           .then((response) => response.json())
           .then((data) => {
             if (!data) {
               console.error("Error on fetching available newsletters");
               return;
             }
             const allNewsletter = { id: "all", name: "Alle" };
             data.unshift(allNewsletter);
             setNewsletters(data);
             setFilterState((prevState) => ({
               ...prevState,
               newsletters: [allNewsletter],
             }));
           });
       }, []);

  const handleNewsletterChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    let newNewsletters: Newsletter[];
    if (
      selectedValues.length === 0 ||
      (selectedValues.length === 1 && selectedValues[0] === "all") ||
      selectedValues[selectedValues.length - 1] === "all"
    ) {
      // If "all" is selected or nothing selected, or last action ( last element ) was clicking all -> set all
      // -> unselect everything else
      newNewsletters = [newsletters[0]];
    } else {
      // Otherwise, filter selected newsletters
      newNewsletters = newsletters.filter(
        (nl) => selectedValues.includes(nl.id) && nl.id !== "all"
      );
    }

    setFilterState((prevState) => ({
      ...prevState,
      newsletters: newNewsletters,
    }));
  };

  const handleMultiSelectChange =
    (filter: string) => (event: SelectChangeEvent<string[]>) => {
      if (event.target.value.includes("all")) {
        setFilterState((prevState) => ({
          ...prevState,
          [filter]: [],
        }));
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          [filter]: event.target.value as string[],
        }));
      }
    };

  const handleSelectChange = (filter: string) => (event: SelectChangeEvent) => {
    setFilterState((prevState) => ({
      ...prevState,
      [filter]: event.target.value,
    }));
  };

  const handleInputChange =
    (filter: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setFilterState((prevState) => ({
        ...prevState,
        [filter]: event.target.value,
      }));
    };

  const handleDateChange =
    (field: "startDate" | "endDate") => (date: Dayjs | null) => {
      setFilterState((prev) => ({ ...prev, [field]: date?.toDate() }));
    };

  const startDate = filterState.startDate ? dayjs(filterState.startDate) : null;
  const endDate = filterState.endDate ? dayjs(filterState.endDate) : null;

  const getSelectedNewsletterNames = (
    selectedNewsletters: Newsletter[]
  ): string => {
    if (
      selectedNewsletters.length === 0 ||
      selectedNewsletters[0].id === "all"
    ) {
      return "Alle";
    }
    return selectedNewsletters.map((nl) => nl.name).join(", ");
  };

  const getSelectedOptionNames = (
    selectedValues: string[],
    options: Record<string | number, string> | Newsletter[]
  ): string => {
    if (
      selectedValues.length === 0 ||
      selectedValues.length === Object.keys(options).length
    ) {
      return "Alle";
    }
    if (Array.isArray(options)) {
      // This is for newsletters
      return selectedValues
        .map((id) => options.find((nl) => nl.id === id)?.name)
        .filter(Boolean)
        .join(", ");
    } else {
      // This is for other options like materialActive
      return selectedValues.map((value) => options[value]).join(", ");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        mt={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {filterConfig.includes("startDate") && (
          <DatePicker
            label="Start-Datum"
            value={startDate}
            onChange={handleDateChange("startDate")}
            format={"DD.MM.YYYY"}
          />
        )}
        {filterConfig.includes("endDate") && (
          <DatePicker
            label="End-Datum"
            value={endDate}
            onChange={handleDateChange("endDate")}
            format={"DD.MM.YYYY"}
          />
        )}
        {filterConfig.includes("display") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Darstellung</InputLabel>
            <Select
              value={filterState.display}
              onChange={handleSelectChange("display")}
              MenuProps={MenuProps}>
              {Object.entries(displayOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("customers") && customers.length && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Kunde</InputLabel>
            <Select
              multiple
              value={filterState.customers}
              onChange={handleMultiSelectChange("customers")}
              renderValue={(selected) => (selected as string[]).join(", ")}
              input={<OutlinedInput label="Kunde" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">Alle</MenuItem>
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("newsletters") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Newsletter</InputLabel>
            <Select
              multiple
              value={filterState.newsletters.map((nl) => nl.id)}
              onChange={handleNewsletterChange}
              renderValue={() =>
                getSelectedNewsletterNames(filterState.newsletters)
              }
              input={<OutlinedInput label="Newsletter" />}
              MenuProps={MenuProps}
            >
              {newsletters.map((nl) => (
                <MenuItem key={nl.id} value={nl.id}>
                  {nl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("articleGrouping") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Gruppierung nach</InputLabel>
            <Select
              value={filterState.articleGrouping}
              onChange={handleSelectChange("articleGrouping")}
              MenuProps={MenuProps}
            >
              {Object.entries(articlesGroupingOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("articleType") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Artikel-Typ</InputLabel>
            <Select
              value={filterState.articleType}
              onChange={handleSelectChange("articleType")}
              MenuProps={MenuProps}
            >
              {Object.entries(articleTypeOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("viewType") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Tracking-Tool</InputLabel>
            <Select
              value={filterState.viewType}
              onChange={handleSelectChange("viewType")}
              MenuProps={MenuProps}
            >
              <MenuItem key="viewtype-ft" value="ft">
                Finanztrends
              </MenuItem>
              <MenuItem key="viewtype-ga" value="ga">
                Google Analytics
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("viewChannel") &&
          filterState.viewType === "ga" &&
          filterState.viewReferrer === "all" && (
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel>Tracking-Kanal</InputLabel>
              <Select
                value={filterState.viewChannel}
                onChange={handleSelectChange("viewChannel")}
                MenuProps={MenuProps}
              >
                {Object.entries(viewChannelOptions).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        {filterConfig.includes("viewReferrer") &&
          filterState.viewType === "ga" &&
          filterState.viewChannel === "all" && (
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel>Referrer</InputLabel>
              <Select
                value={filterState.viewReferrer}
                onChange={handleSelectChange("viewReferrer")}
                MenuProps={MenuProps}
              >
                {Object.entries(viewReferrerOptions).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        {filterConfig.includes("leadValue") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Lead-Wert</InputLabel>
            <OutlinedInput
              value={filterState.leadValue}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              onChange={handleInputChange("leadValue")}
              label="Preis"
            />
          </FormControl>
        )}
        {filterConfig.includes("leadPrice") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Lead-Wert</InputLabel>
            <OutlinedInput
              value={filterState.leadPrice}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              onChange={handleInputChange("leadPrice")}
              label="Preis"
            />
          </FormControl>
        )}
        {filterConfig.includes("samGrouping") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>SAM Gruppierung nach</InputLabel>
            <Select
              value={filterState.samGrouping}
              onChange={handleSelectChange("samGrouping")}
              MenuProps={MenuProps}
            >
              {Object.entries(samGroupingOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("lpsLeadsGroupBy") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Gruppieren nach</InputLabel>
            <Select
              value={filterState.lpsLeadsGroupBy}
              onChange={handleSelectChange("lpsLeadsGroupBy")}
              MenuProps={MenuProps}
            >
              {Object.entries(lpsLeadsGroupByOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("leadStatus") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Lead-Status</InputLabel>
            <Select
              value={filterState.leadStatus}
              onChange={handleSelectChange("leadStatus")}
              MenuProps={MenuProps}
            >
              {Object.entries(leadStatusOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("leadSource") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Lead-Quelle</InputLabel>
            <Select
              multiple
              value={filterState.leadSource}
              onChange={handleMultiSelectChange("leadSource")}
              renderValue={(selected) => selected.map((key) => key).join(", ")}
              input={<OutlinedInput label="Lead-Quelle" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">Alle</MenuItem>
              {Object.entries(leadSourceOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("productActive") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Report-Status</InputLabel>
            <Select
              multiple
              value={filterState.productActive as unknown as string[]}
              onChange={handleMultiSelectChange("productActive")}
              renderValue={(selected) =>
                selected.map((key) => activeOptions[Number(key)]).join(", ")
              }
              input={<OutlinedInput label="Report-Status" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">Alle</MenuItem>
              {Object.entries(activeOptions).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {filterConfig.includes("materialActive") && (
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Werbemittel-Status</InputLabel>
            <Select
              multiple
              value={filterState.materialActive as unknown as string[]}
              onChange={handleMultiSelectChange("materialActive")}
              renderValue={(selected) =>
                getSelectedOptionNames(selected as string[], activeOptions)
              }
              input={<OutlinedInput label="Werbemittel-Status" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">Alle</MenuItem>
              {Object.entries(activeOptions).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/*<FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Anzeige</InputLabel>
          <Select
            value={filterState.display}
            onChange={handleSelectChange("display")}
            MenuProps={MenuProps}
          >
            <MenuItem value={"daily"}>Täglich</MenuItem>
            <MenuItem value={"weekly"}>Wöchentlich</MenuItem>
            <MenuItem value={"monthly"}>Monatlich</MenuItem>
          </Select>
        </FormControl>*/}
      </Box>
    </LocalizationProvider>
  );
};

export default FilterBar;
