//./react-app/src/App.tsx
import React, {FC, useState} from "react";
import AdServerStatistics from "./pages/AdServerStatistics";
import {ColorModeContext, useMode} from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import {Navigate, Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";
import {FilterContext, IFilterState} from "./contexts/FilterContext";
import FilterBar from "./components/FilterBar";
import Calendar from "./scenes/calendar";
import {Login} from "./components/LoginComponent";
import moment from "moment";
import LeadTurnoverPage from "./scenes/gurupress/lead-turnover";
import Newsletter from "./scenes/newsletter";
import ArticleStatistics from "./scenes/articles";
import TrafficLeads from "./scenes/traffic-leads";
import SoldLeads from "./scenes/sold-leads";
import ProductStatisticsPage from "./scenes/adserver/productStatisticsPage";
import ListGrowth from "./scenes/mailchimp";
import AdFormStatisticsPage from "./scenes/adserver/AdFormStatisticsPage";
import LeadImportsPage from "./scenes/gurupress/lead-imports";
import PushStatisticsPage from "./scenes/push/PushStatisticsPage";
import ArticleEvaluationPage from "./scenes/articles/ArticleEvaluationPage";
import WelcomeFlowsPage from "./scenes/adserver/WelcomeFlowsPage";
import RoiEvaluationPage from "./scenes/vnr/roi-evaluation";
import ExportedLeadsPage from "./scenes/leads/exported-leads";
import DailyControllingPage from "./scenes/daily-controlling";
import BGDailyControllingPage from "./scenes/gurupress/daily-controlling";
import DailyControllingEmail from "./scenes/TestPage";
import ArticleDistributionPage from "./scenes/gurupress/article-distribution";
import SWDailyControllingPage from "./scenes/stock-world/daily-controlling";

const App: FC = () => {
    const [theme, colorMode] = useMode();
    // const isDev = process.env.REACT_APP_SERVER_TYPE === 'development';
    const [filterState, setFilterState] = useState<IFilterState>({
        // startDate: isDev ? new Date('2024-10-20') : moment().subtract(14, "days").toDate(),
        // endDate: isDev ? new Date('2024-11-03') : new Date(),
        startDate: moment().subtract(14, "days").toDate(),
        endDate: new Date(),
        customers: [],
        newsletters: [],
        display: "daily",
        articleGrouping: "author",
        articleType: "all",
        viewType: "ft",
        viewChannel: "all",
        viewReferrer: "all",
        leadValue: 8,
        leadPrice: 12.5,
        samGrouping: "send_type",
        customerData: [],
        hostName: "finanztrends.de",
        leadSource: ["all"],
        leadStatus: "all",
        lpsLeadsGroupBy: "lead_status",
        productActive: [1],
        materialActive: [],
        custom: {},
    });

    const isLoggedIn = localStorage.getItem("isLoggedIn");

    return (
        <FilterContext.Provider value={{filterState, setFilterState}}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <div className="app">
                        <Helmet>
                            <script src="http://localhost:8097"></script>
                        </Helmet>
                        {isLoggedIn ? (
                            <>
                                <Sidebar/>
                                <main className="content">
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "leadSource",
                                                            "hostName",
                                                        ]}
                                                    />
                                                    <Dashboard/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/daily-controlling"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "leadStatus",
                                                        ]}
                                                    />
                                                    <DailyControllingPage
                                                        defaultLeadStatus="new"
                                                        defaultStartDate={moment()
                                                            .subtract(3, "months")
                                                            .toDate()}
                                                    />
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/adserver"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "customers"]}
                                                    />
                                                    <AdServerStatistics/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/calendar"
                                            element={
                                                <>
                                                    <FilterBar filterConfig={[]}/>
                                                    <Calendar/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/newsletter"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "samGrouping",
                                                            "customers",
                                                        ]}
                                                    />
                                                    <Newsletter/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/traffic-leads"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "lpsLeadsGroupBy",
                                                            "leadSource",
                                                            "leadStatus",
                                                            "display",
                                                        ]}
                                                    />
                                                    <TrafficLeads/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/sold-leads"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "customers"]}
                                                    />
                                                    <SoldLeads/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/adserver/product-statistics"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "customers",
                                                            "productActive",
                                                            "materialActive",
                                                        ]}
                                                    />
                                                    <ProductStatisticsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/adserver/ad-forms"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "customers",
                                                            "materialActive",
                                                            "newsletters",
                                                        ]}
                                                    />
                                                    <AdFormStatisticsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/adserver/welcome-flows"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "leadPrice"]}
                                                    />
                                                    <WelcomeFlowsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/mailchimp"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "display"]}
                                                    />
                                                    <ListGrowth/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/vnr/roi"
                                            element={
                                                <>
                                                    <FilterBar filterConfig={["startDate", "endDate"]}/>
                                                    <RoiEvaluationPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/redaktion"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "articleGrouping",
                                                            "articleType",
                                                            "viewType",
                                                            "viewChannel",
                                                            "viewReferrer",
                                                            "leadValue",
                                                        ]}
                                                    />
                                                    <ArticleStatistics/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/redaktion/artikel-auswertung"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "articleType",
                                                        ]}
                                                    />
                                                    <ArticleEvaluationPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/push"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "customers"]}
                                                    />
                                                    <PushStatisticsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/leads/exports"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={["startDate", "endDate", "display"]}
                                                    />
                                                    <ExportedLeadsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/boerse-global/daily-controlling"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "leadStatus",
                                                        ]}
                                                    />
                                                    <BGDailyControllingPage
                                                        defaultLeadStatus="new"
                                                        defaultStartDate={moment()
                                                            .subtract(3, "months")
                                                            .toDate()}
                                                    />
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/boerse-global/article-distribution"
                                            element={
                                                <>
                                                    <FilterBar filterConfig={["startDate", "endDate"]}/>
                                                    <ArticleDistributionPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/gurupress/lead-turnover"
                                            element={
                                                <>
                                                    <LeadTurnoverPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/gurupress/lead-imports"
                                            element={
                                                <>
                                                    <LeadImportsPage/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/test"
                                            element={
                                                <>
                                                    <DailyControllingEmail/>
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/stock-world/daily-controlling"
                                            element={
                                                <>
                                                    <FilterBar
                                                        filterConfig={[
                                                            "startDate",
                                                            "endDate",
                                                            "leadStatus",
                                                        ]}
                                                    />
                                                    <SWDailyControllingPage
                                                        defaultLeadStatus="new"
                                                        defaultStartDate={moment()
                                                            .subtract(3, "months")
                                                            .toDate()}
                                                    />
                                                </>
                                            }
                                        />
                                    </Routes>
                                </main>
                            </>
                        ) : (
                            <Routes>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="*" element={<Navigate to="/login" replace/>}/>
                            </Routes>
                        )}
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </FilterContext.Provider>
    );
};

export default App;
