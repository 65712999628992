//./react-app/src/scenes/gurupress/daily-controlling.tsx
import Header from "../../components/Header";
import React, { FC, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import DailyControllingTable, {
  DCSoldLeadsTable,
} from "../../components/leads/DailyControllingTable";
import { FilterContext } from "../../contexts/FilterContext";
import moment from "moment/moment";

interface DailyControllingPageProps {
  defaultLeadStatus: string;
  defaultStartDate: Date;
}

const SWDailyControllingPage: FC<DailyControllingPageProps> = ({
  defaultLeadStatus,
  defaultStartDate,
}) => {
  const { setFilterState } = useContext(FilterContext)!;

  useEffect(() => {
    setFilterState((prevState) => ({
      ...prevState,
      leadStatus: defaultLeadStatus,
      startDate: defaultStartDate,
    }));

    // Reset to App
    return () => {
      setFilterState((prevState) => ({
        ...prevState,
        leadStatus: "all",
        startDate: moment().subtract(14, "days").toDate(),
      }));
    };
  }, [defaultLeadStatus, setFilterState]);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Daily Controlling Stock-World"
          subtitle="Auswertungen zur Lead-Generierung und -Exports"
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box>
          <Box>
            <Box className="mt-2 !mb-2">
              <h2 className="!text-lg !font-bold">Verkaufte Leads</h2>
              <DCSoldLeadsTable customer="sw" />
            </Box>
            <Box>
              <h2 className="!mt-8 !text-lg !font-bold">Alle Leads</h2>
              <DailyControllingTable customer="sw" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SWDailyControllingPage;
