import { FC, ReactElement, useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import {
  ArticleOutlined,
  ArticleSharp,
  AttachMoneyOutlined,
  AutoGraphOutlined,
  BarChart,
  EmailOutlined,
  GraphicEqOutlined,
  ImportExportOutlined,
  ListAltOutlined,
  NewspaperOutlined,
  SellOutlined,
  SendOutlined,
  StartOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

type ItemProps = {
  title: string;
  to: string;
  icon: ReactElement;
  selected: string;
  setSelected: (selected: string) => void;
};

const Item: FC<ItemProps> = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const titleRouteMapping: { [key: string]: string } = {
  "": "Dashboard",
  calendar: "Kalender",
  // Other mappings...
};

const Sidebar: FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Here we extract the name of the route from the location
  const routeName = location.pathname.substring(1);
  const [selected, setSelected] = useState(titleRouteMapping[routeName] || "");

  // If routeName changes (i.e., the user navigated to another route)
  // we update the selected state
  useEffect(() => {
    setSelected(titleRouteMapping[routeName] || "");
  }, [routeName]);

  return (
    <Box
      className="sidebar"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Dashboard
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Manage Team"
              to="/team"
              icon={<GraphicEqOutlined />}
              selected={selected}
              setSelected={setSelected}
            />*/}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Traffic
            </Typography>
            <Item
              title="Traffic Leads"
              to="/traffic-leads"
              icon={<AutoGraphOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Leads
            </Typography>
            <Item
              title="Daily Controlling"
              to="/daily-controlling"
              icon={<ListAltOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Verkaufte Leads"
              to="/sold-leads"
              icon={<SellOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Exportierte Leads"
              to="/leads/exports"
              icon={<SendOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              AdServer
            </Typography>
            <Item
              title="Report Statistik"
              to="/adserver/product-statistics"
              icon={<BarChart />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Welcome-Flows"
              to="/adserver/welcome-flows"
              icon={<StartOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Ad Form Auswertung"
              to="/adserver/ad-forms"
              icon={<FormatListNumberedRtlIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              E-Mail Marketing
            </Typography>
            <Item
              title="List-Entwicklung"
              to="/mailchimp"
              icon={<TimelineOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Newsletter Kalender"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Newsletter Auswertung"
              to="/newsletter"
              icon={<EmailOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              VNR
            </Typography>
            <Item
              title="ROI-Auswertung"
              to="/vnr/roi"
              icon={<AttachMoneyOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Redaktion
            </Typography>
            <Item
              title="Artikel-Gruppierung"
              to="/redaktion"
              icon={<ArticleOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Artikel-Evaluierung"
              to="/redaktion/artikel-auswertung"
              icon={<ArticleSharp />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Push
            </Typography>
            <Item
              title="Push Auswertung"
              to="/push"
              icon={<SendOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Börse Global
            </Typography>
            <Item
              title="Artikel Exporte/Leads"
              to="/boerse-global/article-distribution"
              icon={<NewspaperOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Daily Controlling"
              to="/boerse-global/daily-controlling"
              icon={<ListAltOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Lead Umsatz"
              to="/gurupress/lead-turnover"
              icon={<GraphicEqOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Lead Imports"
              to="/gurupress/lead-imports"
              icon={<ImportExportOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
             <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Stock-World
            </Typography>
            <Item
              title="Daily Controlling"
              to="/stock-world/daily-controlling"
              icon={<ListAltOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<PieChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};
export default Sidebar;
