/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

//DATA:
/*
 *
 * new data array:
 * {
 * "id": 138,
 * "name": "Neuentwicklung bei Superbatterien",
 * "product": "<a href='https://www.finanztrends.de/adserver/product.php?id=138'>Neuentwicklung bei Superbatterien</a> (ID: 138)",
 * "leads": 151,
 * "lead_price": 12.5,
 * "revenue": 1890,
 * "roi_rate": 0.186842,
 * "roi_30": 0.1768,
 * "available_sams": 2,
 * "sam_send_count": 4,
 * "sam_recipients": 67247,
 * "sam_opens": 13936,
 * "sam_clicks": 231,
 * "sam_leads": 76,
 * "sam_revenue": 1875,
 * "sam_unsubscribed": 31,
 * "sam_tag_removals": 18,
 * "sam_unsubscribed_total": 49,
 * "sam_click_rate": 0.003437,
 * "sam_lead_rate": 0.001129,
 * "sam_unsub_rate": 0.000461
 * "available_ads": 23,
 * "ad_send_count": 28,
 * "ad_recipients": 581966,
 * "ad_opens": 124169,
 * "ad_clicks": 224,
 * "ad_click_rate": 0.001805,
 * "ad_leads": 75,
 * "ad_revenue": 15,
 * "ad_lead_rate": 0.000604,
 * */

// The header mapping for the details table in the popup ( use data from above)
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  product: {
    name: "Produkt/Report",
    type: "string",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  revenue: {
    name: "Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  roi_30: {
    name: "ROI (30d)",
    type: "percentage",
    digits: 2,
    attach: "%",
  },
  roi_rate: {
    name: "ROI (laufend)",
    type: "percentage",
    digits: 2,
    attach: "%",
  },
  available_sams: {
    name: "Verfügbare SAMs",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_send_count: {
    name: "SAM Sendungen",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_recipients: {
    name: "SAM Empfänger",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_opens: {
    name: "SAM Öffnungen",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_clicks: {
    name: "SAM Klicks (Unique)",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_click_rate: {
    name: "SAM Klickrate",
    type: "percentage",
    digits: 4,
    attach: "%",
  },
  sam_leads: {
    name: "SAM Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_lead_rate: {
    name: "SAM Leadrate",
    type: "percentage",
    digits: 4,
    attach: "%",
  },
  sam_revenue: {
    name: "SAM Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  sam_unsubscribed: {
    name: "SAM Abmeldungen",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_tag_removals: {
    name: "SAM Tag-Entfernungen",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_unsubscribed_total: {
    name: "SAM Abmeldungen insg.",
    type: "number",
    digits: 0,
    attach: "",
  },
  sam_unsub_rate: {
    name: "SAM Abmelderate",
    type: "percentage",
    digits: 4,
    attach: "%",
  },
  available_ads: {
    name: "Verfügbare Ads",
    type: "number",
    digits: 0,
    attach: "",
  },
  ad_send_count: {
    name: "Ad Versendungen",
    type: "number",
    digits: 0,
    attach: "",
  },
  ad_opens: {
    name: "Ad Öffner",
    type: "number",
    digits: 0,
    attach: "",
  },
  ad_clicks: {
    name: "Ad Klicks (Unique)",
    type: "number",
    digits: 0,
    attach: "",
  },
  ad_click_rate: {
    name: "Ad Klickrate",
    type: "percentage",
    digits: 4,
    attach: "%",
  },
  ad_leads: {
    name: "Ad Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  ad_lead_rate: {
    name: "Ad Leadrate",
    type: "percentage",
    digits: 4,
    attach: "%",
  },
  ad_revenue: {
    name: "Ad Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
};

type Data = Record<DataKey, string>;
type DataKey = keyof LeadChannelData;
type LeadChannelData = Record<string, any>;

const getData: (
  filterState: IFilterState
) => Promise<Record<string, any>[]> = async (filterState: IFilterState) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    customer_id: filterState.customers,
    active: filterState.productActive,
    ad_material_active: filterState.materialActive,
  };
  const data = (await fetchData<Record<string, any>[]>(
    "/api/adserver/product-statistics",
    options
  )) as Record<string, any>[];
  //convert to numbers where possible
  data.forEach((d) => {
    Object.keys(d).forEach((key) => {
      if (!isNaN(d[key])) {
        d[key] = Number(d[key]);
      }
    });
  });
  //add sam_click_rate, ad_click_rate, sam_lead_rate, ad_lead_rate, sam_unsub_rate
  data.forEach((d) => {
    d.sam_unsubscribed_total = d.sam_unsubscribed + d.sam_tag_removals;
    d.sam_click_rate = d.sam_recipients ? d.sam_clicks / d.sam_recipients : 0;
    d.ad_click_rate = d.ad_opens ? d.ad_clicks / d.ad_opens: 0;
    d.sam_lead_rate = d.sam_recipients ? d.sam_leads / d.sam_recipients : 0;
    d.ad_lead_rate = d.ad_opens ? d.ad_leads / d.ad_opens : 0;
    d.sam_unsub_rate = d.sam_recipients ? d.sam_unsubscribed_total / d.sam_recipients : 0;
    d.leads = d.sam_leads + d.ad_leads;
    d.sam_revenue = d.sam_leads * d.lead_price;
    d.ad_revenue = d.ad_leads * d.lead_price;
    d.revenue = d.sam_revenue + d.ad_revenue;
    d.product =
      "<a target='_blank' rel='noopener' href='https://www.finanztrends.de/adserver/product.php?id=" +
      d.id +
      "'><strong>" +
      d.name +
      "</strong></a> (ID: " +
      d.id +
      ")";
  });
  return data;
};
export const ProductStatisticsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<DataKey>("leads");
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  //for export data, remove product column ( as HTML not allowed in Excel )
  const exportData = data.map((d) => ({ ...d })); // Creating a deep copy
  exportData.forEach((d) => {
    delete d.product;
  });

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() =>
            exportToExcel(exportData, "AdServer-Report-Auswertung")
          }
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {detailsHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<Record<string, any>>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number" || value.type === "percentage" ? (
                      row[key] === null ? (
                        ""
                      ) : (
                        formatNumber(
                          Number(row[key]) *
                            (value.type === "percentage" ? 100 : 1),
                          value.digits
                        ) + (value.attach || "")
                      )
                    ) : key === "product" ? (
                      <span dangerouslySetInnerHTML={{ __html: row[key] }} />
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ProductStatisticsTable;
